import React from "react"
import desarrolloCss from "../../css/desarrollo.module.css"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Mobile from "../../components/images/desarrolloMobil/mobil"
import MenuDesarrollo from "../../components/menuDesarrollo/menuDesarrollo"
import LogoBlanco from "../../images/logo-blanco.svg"
import { Link } from "gatsby"

const MobileDevelopment = () => (
  <>
    <div className={desarrolloCss.background}>
      <div className={desarrolloCss.gradient}></div>
    </div>
    <Mobile></Mobile>
    <Link to="/" className={desarrolloCss.logoBlanco}>
    <img src={LogoBlanco} alt="Logotipo de engrane version blanca"/>
    </Link>
    <MenuDesarrollo></MenuDesarrollo>
    <div className={desarrolloCss.container}>
      <Layout siteTitle="Desarrollo">
        <SEO title="Desarrollo Movil" />
        <div className={desarrolloCss.contenido}>
          <h1 className={desarrolloCss.titulo}>
            app <br />
            mobile
          </h1>
          <p className={desarrolloCss.text}>
            ¿Imaginaste una aplicación grandiosa para los dispositivos móviles?o
            tal vez quieres una aplicación móvil para tu negocio o empresa, ¿has
            pensado controlar todo tu inventario y hacer ventas desde un
            smartphone? En la actualidad eso es posible y nosotros somos
            especialistas en el desarrollo móvil.
          </p>
          <br/>
          <p className={desarrolloCss.text}>No te vas a arrepentir.</p>
        </div>
      </Layout>
    </div>
    <Link className={desarrolloCss.productos}>
      PRODUCTOS <span>&nbsp;&nbsp;&nbsp;→</span>
    </Link>
  </>
)

export default MobileDevelopment
