import React, { Component } from 'react';
import menuDesarrolloCss from "./menuDesarrollo.module.css";
import { Link } from 'gatsby';

class MenuDesarrollo extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         }
    }
    render() { 
        return ( 
            <div className={menuDesarrolloCss.container}>
                <Link to="/Develop/Web" className={menuDesarrolloCss.link}>Desarrollo de sitio web</Link>
                <Link to="/Develop/Mobile" className={menuDesarrolloCss.link}>aplicaciones moviles</Link>
                <Link to="/Develop/Alexa" className={menuDesarrolloCss.link}>comandos alexa</Link>
                <Link to="/Develop/Excel" className={menuDesarrolloCss.link}>automatización de procesos con marcos de excel</Link>
                <Link to="/Develop/ChatBot" className={menuDesarrolloCss.link}>chat bot</Link>
                <Link to="/Develop/DataBase" className={menuDesarrolloCss.link}>administración de base de datos</Link>
            </div>
         );
    }
}
 
export default MenuDesarrollo;